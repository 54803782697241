// components/NotFound/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="not-found w-full h-full flex justify-center items-center py-72 xl:py-[20rem]">
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  );
};

export default NotFound;
